import { Card, CardContent } from "@/components/ui/card";
import { Code2, Palette, Rocket } from "lucide-react";

export const About = () => {
  const skills = [
    {
      icon: <Code2 className="h-8 w-8 text-primary" />,
      title: "Development",
      description: "Building robust applications with modern technologies"
    },
    {
      icon: <Palette className="h-8 w-8 text-primary" />,
      title: "Design",
      description: "Creating beautiful and intuitive user interfaces"
    },
    {
      icon: <Rocket className="h-8 w-8 text-primary" />,
      title: "Strategy",
      description: "Helping businesses achieve their digital goals"
    }
  ];

  return (
    <section id="about" className="py-20 px-4 bg-secondary/50">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-16">About Me</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {skills.map((skill, index) => (
            <Card key={index} className="card-hover">
              <CardContent className="pt-6 text-center">
                <div className="mb-4 flex justify-center">{skill.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{skill.title}</h3>
                <p className="text-muted-foreground">{skill.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};