import { Button } from "@/components/ui/button";
import { ArrowDown } from "lucide-react";

export const Hero = () => {
  const scrollToAbout = () => {
    document.getElementById("about")?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="min-h-screen flex items-center justify-center hero-gradient">
      <div className="max-w-4xl mx-auto px-4 py-16 text-center">
        <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary to-green-400">
          Hello, I'm Rohil
        </h1>
        <p className="text-xl md:text-2xl text-muted-foreground mb-8">
          Backend Developer & Football Enthusiast
        </p>
        <p className="text-lg md:text-xl max-w-2xl mx-auto mb-12 text-muted-foreground">
          I create beautiful, functional websites and applications that help
          businesses grow and succeed.
        </p>
        <Button onClick={scrollToAbout} size="lg" className="group">
          Learn More
          <ArrowDown className="ml-2 h-4 w-4 group-hover:translate-y-1 transition-transform" />
        </Button>
      </div>
    </section>
  );
};
