import { Button } from "@/components/ui/button";
import { Github, Instagram, Linkedin, Mail } from "lucide-react";

export const Contact = () => {
  const socialLinks = [
    {
      icon: <Github className="h-6 w-6" />,
      href: "https://github.com/rohilh",
      label: "GitHub",
    },
    { icon: <Linkedin className="h-6 w-6" />, href: "#", label: "LinkedIn" },
    {
      icon: <Instagram className="h-6 w-6" />,
      href: "https://www.instagram.com/rohilh/",
      label: "Instagram",
    },
    {
      icon: <Mail className="h-6 w-6" />,
      href: "mailto:rohilh@gmail.com",
      label: "Email",
    },
  ];

  return (
    <section className="py-20 px-4 bg-secondary/50">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">Get In Touch</h2>
        <p className="text-lg text-muted-foreground mb-12 max-w-2xl mx-auto">
          I'm always interested in hearing about new projects and opportunities.
          Feel free to reach out if you'd like to connect!
        </p>
        <div className="flex justify-center gap-6">
          {socialLinks.map((link, index) => (
            <Button
              key={index}
              variant="ghost"
              size="icon"
              asChild
              className="hover:text-primary transition-colors"
            >
              <a href={link.href} aria-label={link.label}>
                {link.icon}
              </a>
            </Button>
          ))}
        </div>
      </div>
    </section>
  );
};
